var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"d-flex",style:(_vm.getStyleInDocument)},[_c('el-select',{staticClass:"fieldEntity",style:(_vm.getStyleInDocument),attrs:{"disabled":_vm.isDisable,"clearable":"","filterable":""},on:{"change":function($event){return _vm.updateEntityData(true)}},model:{value:(_vm.entityDataId),callback:function ($$v) {_vm.entityDataId=$$v},expression:"entityDataId"}},[(
        _vm.form.field_type == 'ENTITY_VARIABLE' &&
        (!_vm.form.key.includes('~') || _vm.form.key.includes('-')) &&
        _vm.allEntityDataList
      )?_vm._l((_vm.allEntityDataList.filter(
          (e) => _vm.default_entity_ids.indexOf(e._id) !== -1
        )),function(temp,i){return _c('el-option',{key:i,attrs:{"value":temp._id,"label":_vm.getLabel(temp, i)}},[_vm._v(_vm._s(_vm.getLabel(temp, i))+" ")])}):_vm._l((_vm.allEntityDataList),function(temp,i){return _c('el-option',{key:i,attrs:{"value":temp._id,"label":_vm.getLabel(temp, i)}},[_vm._v(_vm._s(_vm.getLabel(temp, i))+" ")])})],2),_c('div',[(_vm.isAllowToAddNewdata || this.form.allow_to_add_new_entity_data)?_c('el-link',{attrs:{"disabled":_vm.isDisable,"underline":false},on:{"click":_vm.openNewQuickAddEntityData}},[_c('i',{staticClass:"el-icon-circle-plus-outline ml-1"})]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }