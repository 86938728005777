<template>
  <div v-loading="loading" :style="getStyleInDocument" class="d-flex">
    <el-select
      v-model="entityDataId"
      @change="updateEntityData(true)"
      :disabled="isDisable"
      clearable
      :style="getStyleInDocument"
      filterable
      class="fieldEntity"
    >
      <template
        v-if="
          form.field_type == 'ENTITY_VARIABLE' &&
          (!form.key.includes('~') || form.key.includes('-')) &&
          allEntityDataList
        "
      >
        <el-option
          v-for="(temp, i) in allEntityDataList.filter(
            (e) => default_entity_ids.indexOf(e._id) !== -1
          )"
          :key="i"
          :value="temp._id"
          :label="getLabel(temp, i)"
          >{{ getLabel(temp, i) }}
        </el-option>
      </template>
      <template v-else>
        <el-option
          v-for="(temp, i) in allEntityDataList"
          :key="i"
          :value="temp._id"
          :label="getLabel(temp, i)"
          >{{ getLabel(temp, i) }}
        </el-option>
      </template>
    </el-select>
    <div>
      <el-link
        :disabled="isDisable"
        v-if="isAllowToAddNewdata || this.form.allow_to_add_new_entity_data"
        :underline="false"
        @click="openNewQuickAddEntityData"
      >
        <i class="el-icon-circle-plus-outline ml-1"></i>
      </el-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../../main";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import { makeSlug } from "@/helpers/appHelper";
export default {
  mixins: [CustomDashboardConfig],
  props: [
    "entityId",
    "form",
    "isDisable",
    "company_id",
    "document_id",
    "itemEntityDataId",
    "isFromTemplate",
    "isAllowToAddNewdata",
    "selectedElement",
    "allowAddDataEntity",
    "documentUsers",
    "getSingleDocumentData",
  ],
  data() {
    return {
      loading: false,
      entityLabel: "",
      allEntityDataList: [],
      entityDataId: "",
      currentEntity: {},
      currentEntityNewAddField: [],
      currentEntityRelationship: {},
      realtionshipFilter: {},
      quickAddedValue: false,
      default_entity_ids: [],
      dataFetched: false,
      relationshipsData: [],
    };
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "getEntityById",
      "getEntityRecordsForTable",
      "getEntitiesDataErrors",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
    ]),
    getStyleInDocument() {
      return `height:${this.form.height}px; width:${
        this.form.width
      }px !important; font-size:${
        this.form.font && this.form.font.size ? this.form.font.size : "16"
      }px !important; font-style:${
        (this.form && this.form.font && this.form.font.style == 2) ||
        (this.form && this.form.font && this.form.font.style == 3)
          ? "italic"
          : "normal"
      } !important; font-weight:${
        (this.form && this.form.font && this.form.font.style == 1) ||
        (this.form && this.form.font && this.form.font.style == 3)
          ? "bold"
          : "normal"
      } !important; color:${
        this.form && this.form.font && this.form.font.color
          ? this.form.font.color
          : "black"
      } !important; font-family:${
        this.form && this.form.font && this.form.font.name
          ? this.form.font.name
          : "helvetica"
      } !important; padding: 0px !important;`;
    },
  },
  watch: {},
  created() {
    bus.$on("entity-selected", () => {
      this.fetchEntityById();
    });
    bus.$on("entityDataId-selected", (data) => {
      if (data.entityDataId && data.form && data.form.key == this.form.key) {
        if (typeof data.entityDataId === "object") {
          this.entityDataId = data.entityDataId[0];
          this.form.entity_data_id = data.entityDataId[0];
        } else {
          this.entityDataId = data.entityDataId;
          this.form.entity_data_id = data.entityDataId;
        }
        if (data.isEntityVariable) {
          if (typeof data.entityDataId === "object") {
            this.default_entity_ids = [...data.entityDataId];
          } else {
            this.default_entity_ids = [data.entityDataId];
          }
        }
        this.updateEntityData();
      } else if (
        data.reset &&
        !data.entityDataId &&
        data.form &&
        data.form.key == this.form.key
      ) {
        this.entityDataId = "";
        this.form.entity_data_id = "";
        this.updateEntityData();
        if (data.isEntityVariable) {
          this.default_entity_ids = [];
        }
      }
    });
    bus.$on("entity-rd-updated", (data) => {
      if (data && data.field) {
        let reload = false;
        if (this.allowAddDataEntity) {
          data.field.value = data.label;
        }
        if (this.form?.filters) {
          this.form.filters =
            this.form &&
            this.form.filters.map((e) => {
              if (
                (e.value_field && e.data_source == "self_field") ||
                e.data_source == "parent_entity_data_id"
              ) {
                if (
                  e.value_field === data.field.key ||
                  e.relation_entity_id == data.field.entity_id
                ) {
                  reload = true;
                  e.value = data.value;
                }
              }
              return e;
            });
        }
        if (reload) {
          this.fetchEntitiesDataForTable();
        }
      }
    });

    bus.$on("entity-ur-updated", (data) => {
      if (data && data.field && data.field.key == this.form.key) {
        this.form.filters = [...data.field.filters];
        this.fetchEntitiesDataForTable();
      }
    });
    bus.$on("quick-add-new-updated", (data) => {
      if (
        data &&
        data.entity_data_id &&
        data.form &&
        data.form.key == this.form.key
      ) {
        this.entityDataId = data.entity_data_id;
        this.form.entity_data_id = data.entity_data_id;
        this.fetchEntitiesDataForTable();
        setTimeout(() => {
          this.updateEntityData();
        }, 2000);
      }
    });
  },
  async mounted() {
    this.loading = true;
    await this.fetchRelationships();
    await this.fetchEntityById();
    this.loading = false;
  },
  methods: {
    async fetchRelationships() {
      await this.$store.dispatch(
        "entityRelationships/fetchEntityRelationships",
        {
          entity_id: this.entityId,
        }
      );
      if (this.getEntityRelationships) {
        let relationshipsData = [...(this.getEntityRelationships || [])];

        relationshipsData = relationshipsData.map((e) => {
          if (e.parent_entity_id == this.entityId) {
            e.owner_type = "PARENT";
            e.relationship_title = e.child_relation_title;
            e.relationship_slug = makeSlug(e.relationship_title);
            e.relationship_id = e.child_entity_id;
          } else {
            e.owner_type = "CHILD";
            e.relationship_title = e.parent_relation_title;
            e.relationship_slug = makeSlug(e.relationship_title);
            e.relationship_id = e.parent_entity_id;
          }
          e.parent_entity_data_id = this.entityDataId;
          return e;
        });

        relationshipsData = relationshipsData.filter((e) => {
          if (e.owner_type == "CHILD" && !e.is_bidirectional) {
            return false;
          } else {
            return true;
          }
        });
        this.relationshipsData = [...relationshipsData];
      }
    },
    async openNewQuickAddEntityData() {
      let primaryFields = [];
      if (
        this.currentEntity &&
        this.currentEntity.entity_type &&
        this.currentEntity.entity_type == "INDIVIDUAL"
      ) {
        this.standardTemplate = this.currentEntity.templates.find(
          (e) =>
            e.templateInfo &&
            e.templateInfo.slug == "contactdetails" &&
            e.templateInfo.type == "STANDARD"
        );
        if (this.standardTemplate) {
          let allFields = this.getTemplateFields(
            this.standardTemplate.templateInfo
          );
          primaryFields = allFields.filter(
            (e) =>
              e.key == "email" || e.key == "last_name" || e.key == "first_name"
          );
        }
      } else if (
        this.currentEntity &&
        this.currentEntity.entity_type &&
        this.currentEntity.entity_type != "INDIVIDUAL"
      ) {
        primaryFields = this.currentEntity.primaryFields;
      }
      let allFields = this.getAllFieldsFromEntity(this.currentEntity, false),
        addUserRelationship = false;
      if (
        this.form.filters.findIndex(
          (e) => e.data_source == "user_entity" && e.data_type == "RELATION"
        ) !== -1
      ) {
        addUserRelationship = true;
      }
      primaryFields = primaryFields.map((el) => {
        let field = allFields.find(
          (e) => e.key == el.key && e.template_id == el.template_id
        );
        if (addUserRelationship) {
          let relationship = this.relationshipsData.find(
            (x) =>
              x.field_id == field._id && x.child_entity_id == field.entity_id
          );
          let currentUser = this.documentUsers.find(
            (e) =>
              e.value == this.form.selected_user &&
              (field.entity_id == e?.contact_type?._id ||
                field.entity_id == e?.contact_type)
          );
          if (
            currentUser?.entity_data_id &&
            currentUser?.email &&
            relationship
          ) {
            let dataId = currentUser?.entity_data_id._id
              ? currentUser?.entity_data_id._id
              : currentUser.entity_data_id;
            field.default_entity_data_ids = [dataId];
          }
        }
        return field;
      });
      bus.$emit("open-quick-entity-add-individual", {
        entityField: this.form,
        field: primaryFields,
        currentEntity: this.currentEntity,
        standardTemplate: this.standardTemplate,
        relationshipsData: this.relationshipsData,
      });
    },
    getAllFieldsFromEntity(entity) {
      let allFields = [];
      let data = JSON.parse(JSON.stringify(entity));
      data.templates.forEach((template) => {
        if (template?.templateInfo?.sections) {
          allFields = [
            ...allFields,
            ...this.getTemplateFields(template?.templateInfo),
          ];
        }
      });
      return allFields;
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    updateEntityData(changed) {
      if (this.dataFetched) {
        if (this.entityDataId) {
          console.log(this.entityDataId);
          let selectedEntityData = this.allEntityDataList.find(
            (e) => e._id == this.entityDataId
          );
          this.$set(this.form, "entity_data_id", this.entityDataId);
          console.log(
            "----------selectedEntityData-----------",
            selectedEntityData
          );
          if (selectedEntityData) {
            let label = this.getLabel(selectedEntityData, 1);
            this.entityLabel = label;
            bus.$emit("entityData-updated", {
              entityDataId: this.entityDataId,
              field: this.form,
              data: selectedEntityData.entityData
                ? selectedEntityData.entityData
                : {},
              value: label,
              changed: changed,
            });
            this.$set(this.form, "value", label);
          } else {
            bus.$emit("entityData-updated", {
              entityDataId: "",
              field: this.form,
              data: {},
              value: "",
              changed: changed,
            });
          }
        } else {
          this.$set(this.form, "entity_data_id", "");
          this.$set(this.form, "value", "");
          bus.$emit("entityData-updated", {
            entityDataId: "",
            field: this.form,
            data: {},
            value: "",
            changed: changed,
          });
        }
        console.log("this.form--this.form", this.form);
        if (this.allowAddDataEntity) {
          //have to throw for relational data updates
          bus.$emit("entity-rd-updated", {
            value: this.entityDataId,
            field: this.form,
            label: this.entityLabel,
          });
        } else {
          bus.$emit("entity-rd-updated", {
            value: this.entityDataId,
            field: this.form,
          });
        }
      }
    },
    async fetchEntitiesDataForTable(dataIds = []) {
      this.loading = true;
      let params = {
        entity_id: this.entityId,
        template_fields_data: [],
        filters: this.mapDateFilters(this.form.filters || []),
      };

      if (dataIds.length) {
        params.entity_data_ids = dataIds;
      }

      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      if (this.getEntityRecordsForTable) {
        this.dataFetched = true;
        this.allEntityDataList = this.getEntityRecordsForTable.data;
        if (this.form) {
          this.entityDataId = this.form.entity_data_id
            ? this.form.entity_data_id
            : "";
          // this.updateEntityData();
        }
        let allIds = this.allEntityDataList.flatMap((e) => e._id);
        if (this.entityDataId && allIds.indexOf(this.entityDataId) === -1) {
          this.entityDataId = "";
          this.updateEntityData();
        }
        if (this.allEntityDataList.length === 1) {
          this.entityDataId = this.allEntityDataList[0]._id;
          this.updateEntityData();
        } else if (this.entityDataId) {
          this.updateEntityData();
        }
        if (
          (!this.form?.filters || !this.form.filters.length) &&
          this.entityDataId &&
          this.form?.field_type == "ENTITY_VARIABLE" &&
          (!this.form.key.includes("~") || this.form.key.includes("-"))
        ) {
          this.default_entity_ids = [this.entityDataId];
        }
        this.loading = false;
      } else {
        this.dataFetched = true;
        this.loading = false;
      }
    },
    async fetchEntityById() {
      let companyId =
        this.getSingleDocumentData && this.getSingleDocumentData.company_id;
      let params = {
        entity_id: this.entityId,
        company_id: companyId,
      };
      await this.$store.dispatch(
        "entities/fetchEntityByIdForEntityField",
        params
      );
      if (this.getEntityById) {
        this.currentEntity = this.getEntityById;
        this.fetchEntitiesDataForTable();
      } else {
        this.loading = false;
      }
    },
    async getValue() {
      let filteredFields = [];
      if (this.filters && this.filters.length) {
        this.filters.forEach((e) => {
          if (
            (e.value_field && e.data_source == "self_field") ||
            e.data_source == "parent_entity_data_id"
          ) {
            filteredFields.push(e.value_field);
          }
        });
      }
      if (filteredFields.length) {
        this.updateFilters();
      } else {
        await this.fetchEntitiesDataForTable();
      }
    },
    updateFilters() {
      this.filters = this.filters.map((e) => {
        if (e && e.data_source == "self_field" && e.value_field) {
          e.value = this.form[e.value_field];
        }
        return e;
      });
      this.fetchEntitiesDataForTable();
    },
    setCurrentEntityFilters() {
      this.currentEntityRelationship = this.getCurrentEntityRelationships.find(
        (e) =>
          e.child_entity_id == this.data.entity_id ||
          e.parent_entity_id == this.data.entity_id
      );

      this.currentEntityRelationship2 = (
        this.childRelationshipsData || []
      ).find(
        (e) =>
          e.child_entity_id == this.data.entity_id ||
          e.parent_entity_id == this.data.entity_id
      );

      if (this.currentEntityRelationship) {
        if (
          this.currentEntityRelationship.child_relation_type == "ONE" &&
          this.currentEntityRelationship.parent_relation_type == "ONE"
        ) {
          this.realtionshipFilter = {
            parent_entity_id: this.currentEntityRelationship.child_entity_id,
            child_entity_data_id:
              this.currentEntityRelationship.parent_entity_data_id,
            child_entity_id: this.currentEntityRelationship.parent_entity_id,
            relation_type: "ONE_ONE",
          };
        } else if (
          this.currentEntityRelationship.child_relation_type == "MANY" &&
          this.currentEntityRelationship.parent_relation_type == "ONE"
        ) {
          this.realtionshipFilter = {
            parent_entity_id: this.currentEntityRelationship.child_entity_id,
            child_entity_data_id:
              this.currentEntityRelationship.parent_entity_data_id,
            child_entity_id: this.currentEntityRelationship.parent_entity_id,
            relation_type: "MANY_ONE",
          };
        }
      }
      if (this.currentEntityRelationship2) {
        if (
          this.currentEntityRelationship2.child_relation_type == "ONE" &&
          this.currentEntityRelationship2.parent_relation_type == "ONE"
        ) {
          this.realtionshipFilter = {
            parent_entity_id: this.currentEntityRelationship2.child_entity_id,
            child_entity_data_id:
              this.currentEntityRelationship2.parent_entity_data_id,
            child_entity_id: this.currentEntityRelationship2.parent_entity_id,
            relation_type: "ONE_ONE",
          };
        } else if (
          this.currentEntityRelationship2.child_relation_type == "MANY" &&
          this.currentEntityRelationship2.parent_relation_type == "ONE"
        ) {
          this.realtionshipFilter = {
            parent_entity_id: this.currentEntityRelationship2.child_entity_id,
            child_entity_data_id:
              this.currentEntityRelationship2.parent_entity_data_id,
            child_entity_id: this.currentEntityRelationship2.parent_entity_id,
            relation_type: "MANY_ONE",
          };
        }
      }
    },
    async fetchEntityData() {
      let params = { get_all: true };
      if (this.company_id && this.document_id) {
        params = {
          ...params,
          ...{ company_id: this.company_id, document_id: this.document_id },
        };
      }
      let id = this.entityId;
      await this.$store.dispatch("entities/fetchEntityDataById", {
        id,
        params,
      });
      this.allEntityDataList = [];
      if (this.getEntityDataByEntityId && this.getEntityDataByEntityId.data) {
        this.allEntityDataList = this.getEntityDataByEntityId.data;
      }
      if (this.form) {
        this.entityDataId = this.form.entity_data_id
          ? this.form.entity_data_id
          : "";
        // this.updateEntityData();
      }
    },
    getLabel(item, i) {
      let selectedEntity = this.currentEntity ? this.currentEntity : {};
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (
            item.entity_prime_data &&
            item.entity_prime_data[key] &&
            item.entity_prime_data[key + "/name"]
          ) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                  ? item.entityData[primaryField.template_id][key]
                  : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
  },
  beforeDestroy() {
    bus.$off("entity-selected", () => {
      console.log("destroyed");
      // this.fetchEntityData();
    });
    // bus.$off("entityData-updated");
  },
};
</script>

<style lang="scss" scoped>
.fieldEntity {
  .el-select {
    padding: 0px !important;
    height: 100% !important;
  }
}
</style>
